import React, { useEffect, useState } from 'react';
import IconReviewStarMini from '../ReviewRatings/IconReviewStarMini/IconReviewStarMini';
import IconReviewSearchPage from './IconReviewSearchPage/iconReviewSearchPage';

const ReviewRatings = ({
  currentListing,
  reviews,
  googleReviews,
  onShowModalFromOutside,
  forSearchPage,
}) => {
  const [reviewsAmount, setReviewsAmount] = useState('');
  const [socialbnbRating, setSocialbnbRating] = useState(0);
  const [finalRating, setFinalRating] = useState(0);

  useEffect(() => {
    let googleReviewsFinal = currentListing?.attributes?.publicData?.location?.reviews?.length
      ? currentListing?.attributes?.publicData?.location?.reviews
      : currentListing?.attributes?.publicData?.googleReviews?.length
      ? currentListing?.attributes?.publicData?.googleReviews
      : [];
    let googleReviewsRating =
      typeof currentListing?.rating == 'number'
        ? currentListing?.rating
        : typeof currentListing?.attributes?.publicData?.location?.rating == 'number'
        ? currentListing?.attributes?.publicData?.location?.rating
        : typeof currentListing?.attributes?.publicData?.googleRating == 'number'
        ? currentListing?.attributes?.publicData?.googleRating
        : 0;

    if (reviews?.length > 0) {
      setReviewsAmount({ value: reviews });
      const ratings = reviews.map(review => review?.attributes?.rating ?? 1);
      const sum = ratings.reduce((total, rating) => total + rating, 0);
      const average = sum / ratings.length;
      setSocialbnbRating(average.toFixed(1));
      setFinalRating(socialbnbRating);
    } else if (reviews?.length === 0 && googleReviewsFinal) {
      setReviewsAmount({ value: googleReviewsFinal });
      setFinalRating(+googleReviewsRating);
    } else if (reviews?.length === 0 && googleReviews?.length === 0) {
      setFinalRating(0);
      setReviewsAmount({ value: 0 });
    }
  }, [reviews, googleReviews, finalRating, currentListing]);
  const reviewsNumber =
    reviewsAmount && reviewsAmount?.value?.length > 0 ? reviewsAmount?.value?.length : 0;

  if (
    typeof currentListing?.attributes?.publicData?.location?.rating !== 'number' &&
    forSearchPage
  ) {
    return <></>;
  } else {
    return (
      <div
        data-elb-accomodation={`rating:${finalRating};reviews:${reviewsNumber}`}
        data-elbaction="wait(1000):reviewsLoaded"
      >
        {!forSearchPage ? (
          <IconReviewStarMini
            value={finalRating}
            reviewsAmount={reviewsAmount}
            onShowModalFromOutside={onShowModalFromOutside}
          />
        ) : (
          <IconReviewSearchPage
            value={finalRating}
            reviewsAmount={reviewsAmount}
            onShowModalFromOutside={onShowModalFromOutside}
          />
        )}
      </div>
    );
  }
};

export default ReviewRatings;
