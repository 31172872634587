import React, { useState, useEffect } from 'react';
import css from './SectionImpactAccomodations.module.css';
import { FormattedMessage } from 'react-intl';
import { categoryDefault } from './impactAccomodationsList.enum';
import CategoryButtons from './CategoryButtons';
import Pin_Icon from '../../../../assets/Pin_Icon.svg';
import campingIcon from '../../../../assets/icons/roomtypes/camping.png';
import doubleRoomIcon from '../../../../assets/icons/roomtypes/doublebedroom.png';
import entireAccomodationIcon from '../../../../assets/icons/roomtypes/entire_accomodation.png';
import oneBedroomIcon from '../../../../assets/icons/roomtypes/onebedroom.png';
import sharedBedroomIcon from '../../../../assets/icons/roomtypes/shared_bedroom.png';
import twoBedroomIcon from '../../../../assets/icons/roomtypes/twobedroom.png';
import animal from '../../../../assets/categories/animal.webp';
import education from '../../../../assets/categories/education.webp';
import nature from '../../../../assets/categories/environment.webp';
import equality from '../../../../assets/categories/equality.webp';
import health from '../../../../assets/categories/health.webp';
import sports from '../../../../assets/categories/sports.webp';

const ImpactAccomodations = () => {
  const [selectedCategory, setSelectedCategory] = useState(categoryDefault);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMiddleDevice, setIsMiddleDevice] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      setIsMobileDevice(screenSize < 1025);
      setIsMiddleDevice(screenSize < 1400);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showCategoryElements = array => {
    const maxItems = isMobileDevice ? 4 : isMiddleDevice ? 9 : 8;
    return array.slice(0, maxItems).map((item, i) => {
      const shortName = item?.name.split(' • ')[0] ?? item?.name;
      return (
        <a href={item?.link} key={i} target="_blank">
          <div
            className={css.oneCard}
            data-elbaction={`click:click lp-impct-list-${shortName}-uuid-${item?.link.substring(
              item?.link.lastIndexOf('/') + 1
            )};`}
          >
            <img className={css.img} src={item?.image} alt="socialbnb impact accomdation" />
            <div className={css.spanDescription}>
              <div className={css.listDescriptionWrapper}>
                <p className={css.descriptionTxt}>
                  <span className={css.name}>
                    {shortName.length < 21 ? shortName : shortName.slice(0, 21) + '...'}
                  </span>
                  <span>
                    <FormattedMessage id="landing.accomodations.from" />
                    <span className={css.price}>{item?.price}</span>
                    <FormattedMessage id="landing.accomodations.pernight" />
                  </span>
                </p>
              </div>
              <div className={css.pinlogo}>
                <div>
                  <img
                    src={Pin_Icon}
                    width="18px"
                    style={{ paddingRight: '2px' }}
                    alt="socialbnb impact icon"
                  />
                  <span className={css.location}>{item?.location}</span>
                </div>
                {getIconByRoomtype(item?.name.split(' • ')[1] ?? item?.name)}
              </div>
              <div className={css.projectype}>
                <span className={css.projectImg1}>
                  {getIconByProjecttype(item?.projectType[0])}
                </span>
                <span className={css.projectImg2}>
                  {getIconByProjecttype(item?.projectType[1])}
                </span>
              </div>
            </div>
          </div>
        </a>
      );
    });
  };

  const getIconByRoomtype = roomType => {
    switch (roomType) {
      case 'Entire accommodation':
        return (
          <img
            src={entireAccomodationIcon}
            style={{ height: '35px', width: '40px' }}
            alt="socialbnb entire Accomodation Icon"
          />
        );
      case 'Twin room':
        return (
          <img
            src={twoBedroomIcon}
            style={{ height: '35px', width: '50px' }}
            alt="socialbnb two Bedroom Icon"
          />
        );
      case 'Double room':
        return (
          <img
            src={doubleRoomIcon}
            style={{ height: '35px', width: '50px' }}
            alt="socialbnb double Room Icon"
          />
        );
      case 'One bedroom':
        return (
          <img
            src={oneBedroomIcon}
            style={{ height: '35px', width: '40px' }}
            alt="socialbnb one Bedroom Icon"
          />
        );
      case 'Shared bedroom':
        return (
          <img
            src={sharedBedroomIcon}
            style={{ height: '35px', width: '50px' }}
            alt="socialbnb shared Bedroom Icon"
          />
        );
      case 'Camping':
        return (
          <img
            src={campingIcon}
            style={{ height: '35px', width: '50px' }}
            alt="socialbnb camping Icon"
          />
        );
      default:
        return (
          <img
            src={oneBedroomIcon}
            style={{ height: '35px', width: '40px' }}
            alt="socialbnb one Bedroom Icon"
          />
        );
    }
  };

  const getIconByProjecttype = projectT => {
    switch (projectT) {
      case 'animal':
        return <img src={animal} style={{ height: '27px', width: '27px' }} alt="animal" />;
      case 'education':
        return <img src={education} style={{ height: '27px', width: '27px' }} alt="education" />;
      case 'nature':
        return <img src={nature} style={{ height: '27px', width: '27px' }} alt="nature" />;
      case 'equality':
        return <img src={equality} style={{ height: '27px', width: '27px' }} alt="equality" />;
      case 'health':
        return <img src={health} style={{ height: '27px', width: '27px' }} alt="health" />;
      case 'sports':
        return <img src={sports} style={{ height: '27px', width: '27px' }} alt="sports" />;
      default:
        return '';
    }
  };

  return (
    <div className={css.outerWrapper}>
      <div className={css.container}>
        <CategoryButtons
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          isMobileDevice={isMobileDevice}
        />
        <div className={css.AllCards}>{showCategoryElements(selectedCategory)}</div>
      </div>
    </div>
  );
};

export default ImpactAccomodations;
