import React from 'react';
import css from './FooterNew.module.css';

import { FormattedMessage, useIntl } from '../../util/reactIntl';
import ExternalLink from '../ExternalLink/ExternalLink';

import insta from '../../assets/icons/socialmedia/instagram-iconTenerif.png';
import fb from '../../assets/icons/socialmedia/facebook-iconTenerif.png';
import linkedIn from '../../assets/icons/socialmedia/linkiedin-iconTenerif.png';
import titktok from '../../assets/icons/socialmedia/tiktok-icon.png';
import youtube from '../../assets/icons/socialmedia/youtube-icon.png';
import NamedLink from '../NamedLink/NamedLink';

/* Partners */

/*documents */

const safetyInfoEN =
  'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/safetyInfoEN.pdf';
const safetyInfoDE =
  'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/safetyInfoDE.pdf';
const childProtectionPolicy =
  'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/ChildProtectionPolicy.pdf';
const KinderschutzPolicy =
  'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/KinderschutzPolicy.pdf';

const qualityCriteriaDE =
  'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/qualityCriteriaDE.pdf';
const qualityCriteriaEN =
  'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/qualityCriteriaEN.pdf';

const travelerGuidlines =
  'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/TravelerGuidelines.pdf';
const reisendeGuidlines =
  'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/GuidelineFuerReisende.pdf';

const file2022de =
  'https://drive.google.com/uc?export=download&id=1uLYtbR0XRa25XjPH3BU7Iw3kjf_wS1QU';
const file2022en =
  'https://drive.google.com/uc?export=download&id=1gnQX3QFcWdZMrtgcdNiTiOnb2Zrams-P';
const ambassador = '/ambassadorPage';
const giftcard = '/p/gutschein';

/* const newsDE = 'https://blog.socialbnb.org/de/newsletter-de';
const newsEN = 'https://blog.socialbnb.org/newsletter-eng'; */
const Footer = () => {
  const intl = useIntl();
  const selectedLanguage = intl.locale;

  const currentYear = new Date().getFullYear();
  const scrollToTop = () => {
    if (typeof window !== 'undefined' && window.document) {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={css.footerContainer}>
      <div
        className={css.footerTop}
        data-elb="section"
        data-elb-section="name:footerUpper;position:10"
        data-elbaction="visible:footer visible"
      >
        <div className={css.footerTopText}>
          <div className={css.newsletter}>
            <h2 className={css.title}>
              <FormattedMessage id="newsletter.headline" />
            </h2>
            <p className={css.text}>
              <FormattedMessage id="newsletter.subheadline" />
            </p>

            <ExternalLink href="http://eepurl.com/iKTRes">
              <button
                className={css.subscribeBtn}
                data-elbaction={'click:click footer-newslettersignup'}
              >
                <FormattedMessage id="newsletter.signUp"></FormattedMessage>
              </button>
            </ExternalLink>
          </div>
      <div className={css.divider}></div>
          <div className={css.socialMedia}>
            <h2 className={css.title}>
              <FormattedMessage id="socialmedia.headline" />
            </h2>
            <p className={css.text}>
              <FormattedMessage id="socialmedia.subheadline" />
            </p>

            <div className={css.socialMediaBtns}>
              <ExternalLink href="https://www.instagram.com/socialbnb/" className={css.link}>
                <img
                  src={insta}
                  className={css.socialMediaIcons1}
                  alt="socialbnb instagram "
                  data-elbaction={'click:click footer-instagram'}
                ></img>
              </ExternalLink>
              <ExternalLink href="https://www.facebook.com/Socialbnb" className={css.link}>
                <img
                  src={fb}
                  className={css.socialMediaIcons2}
                  alt="social bnb facebook"
                  data-elbaction={'click:click footer-faceook'}
                ></img>
              </ExternalLink>
              <ExternalLink href="https://www.linkedin.com/company/socialbnb/" className={css.link}>
                <img
                  src={linkedIn}
                  className={css.socialMediaIcons3}
                  alt="social travel linkedin"
                  data-elbaction={'click:click lp-footer-linkedin'}
                ></img>
              </ExternalLink>
              <ExternalLink href="https://www.tiktok.com/@socialbnb" className={css.link}>
                <img
                  src={titktok}
                  className={css.socialMediaIcons4}
                  alt="social experience tiktok"
                  data-elbaction={'click:click lp-footer-tiktok'}
                ></img>
              </ExternalLink>
              <ExternalLink href="https://www.youtube.com/@socialbnb_de" className={css.link}>
                <img
                  src={youtube}
                  className={css.socialMediaIcons4}
                  alt="social experience youtube"
                  data-elbaction={'click:click lp-footer-tiktok'}
                ></img>
              </ExternalLink>
            </div>
          </div>

        </div>
      </div>
      <div
        className={css.footerBottom}
        data-elb="section"
        data-elb-section="name:footerButtom;position:11"
        data-elbaction="visible:footer bottom"
      >
        <div className={css.list1}>
          <ul className={css.leftList}>
            <li className={css.listItem}>
              <h3 className={css.heading}>
                <FormattedMessage id="Footer.intro" />
              </h3>
            </li>
            <li
              className={css.listItem}
              onClick={() => scrollToTop()}
              data-elbaction={'click:click footer-startbutton'}
            >
              <NamedLink name="LandingPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.start" />
              </NamedLink>
            </li>
            <li className={css.listItem} data-elbaction={'click:click footer-aboutpage'}>
              <NamedLink name="AboutPage" className={css.link} onClick={() => scrollToTop()}>
                <FormattedMessage id="TopbarDesktop.about" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <a href={giftcard} className={css.link}>
                <FormattedMessage id="newsletter.voucher" />
              </a>
            </li>
            <li className={css.listItem}>
              <a href={ambassador} className={css.link}>
                <FormattedMessage id="ImpactAmb.link" />
              </a>
            </li>
          </ul>
          <ul className={css.list}>
            <li className={css.listItem}>
              <h3 className={css.heading}>
                <FormattedMessage id="about.impactHeadline" />
              </h3>
            </li>
            <li className={css.listItem} data-elbaction={'click:click footer-worldwide'}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: '?bounds=85.051129%2C179.00736739%2C-75.14077719%2C-160.60200761',
                }}
                className={css.link}
              >
                <FormattedMessage id="TopbarDesktop.accomodations" />
              </NamedLink>
            </li>
            <ul className={css.ulNew}>
              {' '}
              <li className={css.listItem} data-elbaction={'click:click footer-europe'}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: '?bounds=66.75842601%2C44.21184607%2C26.75060877%2C-31.82643116',
                  }}
                  className={css.link}
                >
                  <FormattedMessage id="europe" />
                </NamedLink>
              </li>
              <li className={css.listItem} data-elbaction={'click:click footer-southamerica'}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: '?bounds=34.15056365%2C-25.99456573%2C-59.50348924%2C-150.22941462',
                  }}
                  className={css.link}
                >
                  <FormattedMessage id="southamerica" />
                </NamedLink>
              </li>
              <li className={css.listItem} data-elbaction={'click:click footer-africa'}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: '?bounds=36.59497175%2C52.76846679%2C-36.15117826%2C-34.86957672',
                  }}
                  className={css.link}
                >
                  <FormattedMessage id="africa" />
                </NamedLink>
              </li>
              <li className={css.listItem} data-elbaction={'click:click footer-asia'}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: '?bounds=64.09173416%2C169.49644716%2C-14.97311392%2C58.14770588',
                  }}
                  className={css.link}
                >
                  <FormattedMessage id="asia" />
                </NamedLink>
              </li>
            </ul>
            <li className={css.listItem} data-elbaction={'click:click footer-experience'}>
              <a href="/p/impact-experiences" className={css.link}>
                <FormattedMessage id="TopbarDesktop.experience" />
              </a>
            </li>
            <li className={css.listItem} data-elbaction={'click:click footer-roundtrips'}>
              <a href="/p/impact-trips" className={css.link}>
                <FormattedMessage id="TopbarDesktop.roundTrips" />
              </a>
            </li>
          </ul>
        </div>
        <div className={css.list3}>
          <ul className={css.list}>
            <li className={css.listItem}>
              <h3 className={css.heading}>Downloads</h3>
            </li>
            <li data-elbaction={'click:click footer-safteyinfo'}>
              {selectedLanguage === 'en' ? (
                <ExternalLink href={safetyInfoEN} className={css.link}>
                  <FormattedMessage id="Footer.SafetyInfo" />
                </ExternalLink>
              ) : (
                <ExternalLink href={safetyInfoDE} className={css.link}>
                  <FormattedMessage id="Footer.SicherheitInfo" />
                </ExternalLink>
              )}
            </li>
            <li data-elbaction={'click:click footer-childprotectionpolicy'}>
              {selectedLanguage === 'en' ? (
                <ExternalLink href={childProtectionPolicy} className={css.link}>
                  <FormattedMessage id="Footer.childProtectionPolicy" />
                </ExternalLink>
              ) : (
                <ExternalLink href={KinderschutzPolicy} className={css.link}>
                  <FormattedMessage id="Footer.childProtectionPolicy" />
                </ExternalLink>
              )}
            </li>
            <li data-elbaction={'click:click footer-guideline'}>
              {selectedLanguage === 'en' ? (
                <ExternalLink href={travelerGuidlines} className={css.link}>
                  <FormattedMessage id="Footer.guidline" />
                </ExternalLink>
              ) : (
                <ExternalLink href={reisendeGuidlines} className={css.link}>
                  <FormattedMessage id="Footer.guidline" />
                </ExternalLink>
              )}
            </li>
            <li data-elbaction={'click:click footer-qualitycriteria'}>
              {selectedLanguage === 'en' ? (
                <ExternalLink href={qualityCriteriaEN} className={css.link}>
                  <FormattedMessage id="Footer.qualityCriteria" />
                </ExternalLink>
              ) : (
                <ExternalLink href={qualityCriteriaDE} className={css.link}>
                  <FormattedMessage id="Footer.qualityCriteria" />
                </ExternalLink>
              )}
            </li>
            <li data-elbaction={'click:click footer-impactreport'}>
              {selectedLanguage === 'en' ? (
                <ExternalLink href={file2022en} className={css.link}>
                  <FormattedMessage id="about.impactReportHeadline" />
                </ExternalLink>
              ) : (
                <ExternalLink href={file2022de} className={css.link}>
                  <FormattedMessage id="about.impactReportHeadline" />
                </ExternalLink>
              )}
            </li>
          </ul>
        </div>
        <div className={css.list2}>
          <ul>
            <h3 className={css.heading}>
              <FormattedMessage id="Footer.info" />
            </h3>
            <li className={css.listItem} data-elbaction={'click:click footer-faq'}>
              <NamedLink name="FAQ" className={css.link}>
                <FormattedMessage id="TopbarDesktop.FAQ" />
              </NamedLink>
            </li>

            <li className={css.listItem} data-elbaction={'click:click footer-contact'}>
              <NamedLink name="Contact" className={css.link}>
                <FormattedMessage id="TopbarDesktop.Contact" />
              </NamedLink>
            </li>

            <li className={css.listItem} data-elbaction={'click:click footer-press'}>
              <ExternalLink href="https://www.socialbnb.org/p/presse" className={css.link}>
                <FormattedMessage id="TopbarDesktop.Press" />
              </ExternalLink>
            </li>
            <li className={css.listItem} data-elbaction={'click:click bloglp'}>
              <ExternalLink href="https://www.socialbnb.org/p/blog" className={css.link}>
                <FormattedMessage id="TopbarDesktop.Blog" />
              </ExternalLink>
            </li>
            <li className={css.listItem} data-elbaction={'click:click footer-jobs'}>
              <ExternalLink
                href="https://jobboard.catch-talents.de/companies/136?pageIndex=0"
                className={css.link}
              >
                Jobs
              </ExternalLink>
            </li>
            <li className={css.listItem} data-elbaction={'click:click footer-affiliate'}>
              <a href="/p/affiliatelanding" className={css.link}>
                <FormattedMessage id="Footer.affiliate" />
              </a>
            </li>
            <li className={css.listItem} data-elbaction={'click:click footer-becomehost'}>
              <a href="/BecomeHostPage" className={css.link}>
                <FormattedMessage id="Footer.host" />
              </a>
            </li>
          </ul>
          <ul className={css.list}>
            <li data-elbaction={'click:click footer-legal'}>
              <h3 className={css.heading}>
                <FormattedMessage id="Footer.legal" />
              </h3>
            </li>
            <li data-elbaction={'click:click footer-imprint'}>
              <NamedLink name={'impressum'} className={css.link}>
                <FormattedMessage id="Footer.imprint" />
              </NamedLink>
            </li>
            <li data-elbaction={'click:click footer-termsofuse'}>
              <NamedLink name={'agb'} className={css.link}>
                <FormattedMessage id="Footer.termsOfUse" />
              </NamedLink>
            </li>
            <li data-elbaction={'click:click footer-privacypolicy'}>
              <NamedLink name={'datenschutz'} className={css.link}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </NamedLink>
            </li>
          </ul>
        </div>

        <h2 className={css.copyright}>
          Copyright <span>&copy;</span> {currentYear} socialbnb
        </h2>
      </div>
    </div>
  );
};

export default Footer;
