import css from './menuNew.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import askQuestions from '../../assets/askQuestions.png';
import inbox from '../../assets/inbox.png';
import listingsIcon from '../../assets/listings.png';
import logoutSmallIcon from '../../assets/logoutSmall.png';
import profileIcon from '../../assets/profile.png';
import loginIcon from '../../assets/login.png';
import hostIcon from '../../assets/host.png';
import classNames from 'classnames';
import { LanguageSwitcher } from '../../..';

export const MenuNew = props => {
  const { currentUser, history, onLogout, setMenuOpen } = props;

  const navigate = targetPage => {
    setMenuOpen(prev => !prev);
    history.push(targetPage);
  };

  const isHost = currentUser?.attributes?.profile?.protectedData?.type === 'host';
  const hostListingMenu = {
    label: <FormattedMessage id="navbar.myListings" />,
    icon: listingsIcon,
    onClick: () => navigate('/listings'),
  };
  const menuItems = currentUser
    ? [
        {
          label: <FormattedMessage id="navbar.profile" />,
          icon: profileIcon,
          onClick: () => navigate('/profile-settings'),
        },
        {
          label: <FormattedMessage id="TopbarDesktop.inbox" />,
          icon: inbox,
          onClick: () => navigate(isHost ? '/inbox/sales' : '/inbox/orders'),
        },
      ]
    : [
        {
          label: <FormattedMessage id="navbar.loginRegister" />,
          icon: loginIcon,
          onClick: () => navigate('/login'),
        },
      ];
  if (isHost) {
    menuItems.push(hostListingMenu);
    menuItems.push({
      label: <FormattedMessage id="TopbarDesktop.logout" />,
      icon: logoutSmallIcon,
      onClick: () => {
        setMenuOpen(false);
        onLogout();
      },
    });
  } else {
    if (currentUser) {
      menuItems.push({
        label: <FormattedMessage id="TopbarDesktop.logout" />,
        icon: logoutSmallIcon,
        onClick: () => {
          setMenuOpen(false);
          onLogout();
        },
      });
    }
  }
  return (
    <div className={css.menuContainer}>
      <div className={css.input}>
        {menuItems.map((item, index) => (
          <button
            key={index}
            className={classNames(css.value, item.className)}
            onClick={item.onClick}
            data-elbaction={item.label.props.id === "navbar.loginRegister" ? 'click:click topbar-login/register' : null}
          >
            <img src={item.icon} className={css.icon} alt={`${item.label} icon`} />
            {item.label}
          </button>
        ))}
        <div className={css.languageSwitcherMobile}>
          {' '}
          <LanguageSwitcher onMobile={true} />
        </div>
        {!currentUser ? (
          <button
            className={classNames(css.value, css.hostMenuItem)}
            onClick={() => navigate('/BecomeHostPage')}
            data-elbaction="click:click topbar-becomehost"
          >
            <img src={hostIcon} className={css.icon} />
            {'Become a host'}
          </button>
        ) : (
          <></>
        )}
        <button
          className={classNames(css.value, css.questionIconClass)}
          onClick={() => navigate('/Contact')}
          data-elbaction="click:click topbar-askQuestion"
        >
          <img src={askQuestions} className={css.icon} />
          <FormattedMessage id="navbar.askQuestion" />
        </button>
      </div>
    </div>
  );
};
