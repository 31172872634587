import React, { useEffect, useRef } from 'react';
import css from './SectionImpactAccomodations.module.css';
import {
  categoryDefault,
  category0,
  category2,
  category4,
  category5,
  category6,
  category8,
  category9,
  category10,
  category11,
  category13,
  category15,
  category16,
} from './impactAccomodationsList.enum';
import { FormattedMessage } from 'react-intl';
import {
  natureIcon,
  adventureIcon,
  beachIcon,
  breakfastIcon,
  campIcon,
  cookingIcon,
  handCraftIcon,
  farmIcon,
  tinyHouseIcon,
  backPackIcon,
  familyIcon,
  amazingviewsIcon,
  countrySideIcon,
  islandsIcon,
  lakeIcon,
  affordableIcon,
  remoteIcon,
} from './categoryIcons';
import star from '../../../../assets/Experiencehighlights/activity.png'
import englishTranslations from '../../../../translations/en.json';

const CategoryButtons = ({
  selectedCategory,
  setSelectedCategory,
  isMobileDevice,
}) => {
  let box;
  if (typeof window !== 'undefined' && window?.document) {
    box = document.getElementById('desktopSlider');
  }
  const categoryData = [
    {
      id: 0,
      categoryName: <FormattedMessage id="landing.accomodations.categoryDefault" />,
      categoryIcon: star,
      category: categoryDefault,
    },
    {
      id: 1,
      categoryName: <FormattedMessage id="landing.accomodations.category6" />,
      categoryIcon: beachIcon,
      category: category6,
    },
    {
      id: 2,
      categoryName: <FormattedMessage id="landing.accomodations.category8" />,
      categoryIcon: farmIcon,
      category: category8,
    },
    {
      id: 3,
      categoryName: <FormattedMessage id="landing.accomodations.category4" />,
      categoryIcon: natureIcon,
      category: category4,
    },
    {
      id: 4,
      categoryName: <FormattedMessage id="landing.accomodations.category2" />,
      categoryIcon: familyIcon,
      category: category2,
    },
    
    {
      id: 5,
      categoryName: <FormattedMessage id="landing.accomodations.category5" />,
      categoryIcon: adventureIcon,
      category: category5,
    },
    
    {
      id: 6,
      categoryName: <FormattedMessage id="landing.accomodations.category16" />,
      categoryIcon: remoteIcon,
      category: category16,
    },
    /* {
      id: 5,
      categoryName: <FormattedMessage id="landing.accomodations.category7" />,
      categoryIcon: breakfastIcon,
      category: category7,
    }, */
    /* {
      id: 6,
      categoryName: <FormattedMessage id="landing.accomodations.category3" />,
      categoryIcon: cookingIcon,
      category: category3,
    }, */
    /* {
      id: 7,
      categoryName: <FormattedMessage id="landing.accomodations.category1" />,
      categoryIcon: handCraftIcon,
      category: category1,
    }, */
    {
      id: 7,
      categoryName: <FormattedMessage id="landing.accomodations.category0" />,
      categoryIcon: affordableIcon,
      category: category0,
    },
    {
      id: 8,
      categoryName: <FormattedMessage id="landing.accomodations.category9" />,
      categoryIcon: tinyHouseIcon,
      category: category9,
    },
    {
      id: 9,
      categoryName: <FormattedMessage id="landing.accomodations.category10" />,
      categoryIcon: campIcon,
      category: category10,
    },
    {
      id: 11,
      categoryName: <FormattedMessage id="landing.accomodations.category11" />,
      categoryIcon: backPackIcon,
      category: category11,
    },
   /*  {
      id: 12,
      categoryName: <FormattedMessage id="landing.accomodations.category12" />,
      categoryIcon: lakeIcon,
      category: category12,
    }, */
    /* {
      id: 13,
      categoryName: <FormattedMessage id="landing.accomodations.category13" />,
      categoryIcon: amazingviewsIcon,
      category: category13,
    }, */
    /* {
      id: 14,
      categoryName: <FormattedMessage id="landing.accomodations.category14" />,
      categoryIcon: countrySideIcon,
      category: category14,
    }, */
    {
      id: 15,
      categoryName: <FormattedMessage id="landing.accomodations.category15" />,
      categoryIcon: islandsIcon,
      category: category15,
    },
    
  ];

  const sliderRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    const handleTouchStart = event => {
      const startX = event.touches[0].clientX;
      event.target.dataset.startX = startX; // Store startX on the element itself
    };

    const handleTouchMove = event => {
      const startX = parseFloat(event.target.dataset.startX);
      const touchX = event.touches[0].clientX;
      const deltaX = startX - touchX;

      // Amplifying the deltaX by multiplying it with a factor (e.g., 1.5 or 2)
      const scrollFactor = 30; // Adjust this factor based on desired responsiveness
      const effectiveDeltaX = deltaX * scrollFactor;

      if (slider) {
        slider.scrollLeft += effectiveDeltaX;
        event.target.dataset.startX = touchX; // Update startX for the next move
      }
      event.preventDefault(); // Prevent the window from scrolling
    };

    if (slider) {
      slider.addEventListener('touchstart', handleTouchStart, false);
      slider.addEventListener('touchmove', handleTouchMove, false);
    }

    return () => {
      if (slider) {
        slider.removeEventListener('touchstart', handleTouchStart);
        slider.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [isMobileDevice]);

 /*  const handleNextClick = () => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      boxRef.current.scrollLeft += width;
    }
  };

  const handlePreviousClick = () => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      boxRef.current.scrollLeft -= width;
    }
  }; */

  return (
    <>
      {isMobileDevice ? (
        <div className={css.mobileCategories}>
          <div className={css.mobileButtonsWrapper}>
            <div className={css.mobileSlider} ref={sliderRef}>
              {categoryData.map(category => (
                <button
                  id={category.id}
                  key={category.id}
                  className={
                    selectedCategory === category.category
                      ? css.mobileCategoryButtonActive
                      : css.mobileCategoryButton
                  }
                  onClick={() => {
                    setSelectedCategory(category.category);
                  }}
                  data-elb-categories={category.id}
                  data-elbaction={
                    'click:click lp-impctcat-' + englishTranslations[`${category.categoryName.props.id}`]
                  }
                >
                  <img
                    src={category.categoryIcon}
                    style={{ paddingRight: '2px', width: '28px', height: '28px' }}
                    alt="category icon"
                  />
                  <div style={{ paddingBottom: '5px' }}>
                    <span className={css.categoryTxtMobile}>{category.categoryName}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={css.desktopBtnOuterWrapper}>
          {/* <button className={css.sliderBtn} onClick={handlePreviousClick}>
            <p className={css.sliderBtnTxt}>&lt;</p>
          </button> */}
          <div id="desktopSlider" className={css.desktopButtonsWrapper} ref={boxRef}>
            {categoryData.map(category => (
              <button
                key={category.id}
                className={
                  selectedCategory === category.category
                    ? css.desktopCategoryButtonActive
                    : css.desktopCategoryButton
                }
                onClick={() => {
                  setSelectedCategory(category.category);
                }}
                data-elb-categories={category.id}
                data-elbaction={
                  'click:click lp-impctcat-' + englishTranslations[`${category.categoryName.props.id}`]
                }
              >
                <img
                  src={category.categoryIcon}
                  style={{ width: '33px', height: '33px' }}
                  alt="socialbnb categories button"
                />
                <div style={{ marginBottom: '10px' }}>
                  <span className={css.categoryTxt}>{category.categoryName}</span>
                </div>
              </button>
            ))}
          </div>
         {/*  <button className={css.sliderBtn} onClick={handleNextClick}>
            <p className={css.sliderBtnTxt}>&gt;</p>
          </button> */}
        </div>
      )}
    </>
  );
};

export default CategoryButtons;
